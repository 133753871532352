import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Layout from "../Layout/Layout";
import flowerStyle from "./flower.module.scss";

function Flower(props) {
  const [fill, setFill] = useState(true);
  setTimeout(() => {
    setFill(false);
  }, 5000);

  return (
    <svg
      className={flowerStyle.svg}
      x="0px"
      y="0px"
      viewBox="0 0 700 700"
      // viewBox="30 210 700 300"
    >
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#1b1924" />
          <stop offset="70%" stop-color="#d84936" />
        </linearGradient>
      </defs>
      <path
        onMouseOver={() => setFill(true)}
        onMouseLeave={() => setFill(false)}
        className={fill ? flowerStyle.pathExcite : flowerStyle.path}
        stroke="url(#linear)"
        strokeWidth="2px"
        fill="url(#linear)"
        d="M545.44,511.82c0.99-2.42-2.66-4.96-1.1-5.51c1.54-0.55,16.78-23.07,16.78-23.07s9.6-13.47,17.1-19.1
	c7.51-5.63,13.47-22.52,13.47-22.52s1.1-4.3-4.75-12.92c-5.84-8.61-5.63-14.57-5.63-14.57s4.15-0.33,10.47,7.95
	c0,0,15.59,10.93,21.45,14.25c0,0,1.1,1,5.08,8.5c3.97,7.51,7.39,15.57,9.05,15.46c1.66-0.11,2.09,0,5.3-4.3
	c3.2-4.3,10.26-4.42,11.37-5.3c1.1-0.88,1.33-2.54-1.99-3.54c-3.31-0.99-9.93-10.37-11.14-26.38c0,0-1.77-4.53,4.53-2.33
	c0,0,7.29,2.88,15.56-0.55c8.28-3.42,8.39-7.5,7.62-9.27c-0.78-1.76-2.65-3.75-13.14-7.72c-10.49-3.97-12.47-7.95-15.89-6.73
	c0,0,0.22-1.43-6.41-4.42c-6.62-2.97-5.85-3.97-5.85-3.97s6.85-0.11,15.68,1.43c8.83,1.55,24.95,0,33.67-2.2
	c8.72-2.21-0.12-5.3,17.33-15.9c0,0,1.66-4.85-4.3-6.29s-8.06,4.3-10.81-0.34c-2.76-4.63-4.97-8.72-2.66-14.24
	c2.32-5.52,7.06-1.99,11.04-10.93c0,0,1.66-3.42-2.09-5.74c-3.75-2.33-6.29-4.09-6.63-7.73c-0.33-3.64-0.44-3.87-3.42-6.18
	c-2.97-2.32-1.87-4.3-5.74-3.2s-10.05,3.09-10.05,3.09s-3.97,0.22-4.3,0.22s-1.1,2.54-8.17,5.51c-7.06,2.98-22.85,13.42-25.73,11.9
	c-2.87-1.52,11.15-9.8,16.23-12.34s4.09-3.64,4.09-3.64s5.51-2.29,8.38-5.07c2.88-2.77,9.27-8.85,9.27-8.85s4.09-3.75,6.63-8.72
	c2.54-4.96,7.06-9.27,2.21-12.14c-4.86-2.87-14.57-7.84-16.68-17.22c-2.09-9.39-1.1-9.39-6.4-11.15c-5.3-1.76-9.6-0.88-12.59,0
	c-2.98,0.88-11.37-1.66-18.32,1.55c-6.96,3.2-12.48,2.75-16.67,3.53c-4.2,0.78-7.84,5.85-7.84,5.85s-6.96,7.62-7.73-2.09
	c-0.78-9.72-0.66-10.93-0.66-10.93s2.32-4.2,1.43-10.82c-0.88-6.62-1.33-7.17,0.66-9.16c1.99-1.99,1.66-3.42-0.44-6.08
	c-2.1-2.65-9.9-8.72-14.35-9.16c0,0-4.64-12.04-12.81-9.39c-8.17,2.66-22.74,4.85-28.59,13.69c-5.85,8.83-8.17,7.62-5.42,14.9
	c2.76,7.29,2.21,9.5,0,11.92c-2.2,2.43-10.71,7.51-15.79,17.11c-5.08,9.6-8.83,6.84-7.72,11.71c1.1,4.85,4.45,7.05,3.96,9.41
	c-0.91,4.39-4.85,0.3-0.6,10.57c4.25,10.26,6.02,8.93,2.15,14.79c0,0-2.54,2.98,3.43,2.87c0,0,2.42,3.21-4.09,3.21
	c-6.51,0-28.7,12.25-28.7,12.25s-20.98,8.94-29.91,17.67c0,0-13.47,2.98-25.83,3.21c0,0-4.53,0.33-6.51-0.89
	c-1.99-1.21-17.17,3.09-19.74,1.22c-2.57-1.88-6.98-6.08-9.96-4.64c-2.97,1.43-6.96,3.76-8.28,3.42
	c-1.33-0.33-10.27-0.99-21.76,1.66c-11.47,2.66-1.21-0.55-45.25,2.54c0,0-3.54,0.12-33.57-2.42c-30.02-2.54-48.02-1.99-48.02-1.99
	s-24.95,0.77-25.28,0.77c-0.33,0-5.08-2.75-12.37-1.66c-7.29,1.1-17.56-1.09-17.56-1.09l-53.76,0l-63.04,1.87
	c0,0-6.73,3.97-9.05,11.81c0,0,2.43,1.88,17.44,0c15.01-1.87,71.76-1.21,71.76-1.21s67.56,2.21,69.77,2.97
	c2.21,0.78,4.85,4.09,13.25,2.76c8.39-1.32,13.36,0.51,23.07,0.31c9.72-0.19,21.42,0.91,26.06,1.46c4.63,0.55,9.27-1.22,13.58-1
	c4.3,0.22,18.55,3.42,24.62,2.65c6.07-0.77,17.22,0.67,22.73-0.55c5.52-1.21,31.47-1.99,31.47-1.99s1.43,1.22,5.96,0.34
	c4.53-0.89,9.17,3.53,9.17,3.53s2.32,4.3,8.17,0c5.85-4.3,11.26-6.84,16.44-6.62c5.19,0.21,24.95,1.32,29.92,2.87
	c4.96,1.54,5.85,4.49,21.52,5.67c0,0,13.92,12.33,18.22,13.54c4.3,1.21,2.32,4.64,18.44,2.66c0,0,7.5-5.85,10.49-4.31
	c2.98,1.55,5.74,4.42,3.97,16.01c-1.76,11.59,1.05,20.31,1.05,20.31s1.27,2.54,0.94,5.97c-0.33,3.42,2.98,6.17,2.98,6.17
	s-3.76,12.59,0,19.88l0.88,7.62c0,0,2.87,3.09,3.87,4.64c0.99,1.54,8.61-0.22,9.82-3.64c1.22-3.42,14.14-24.18,17.78-27.16
	c0,0,1.97,3.76,3.14,8.72c1.16,4.97,1.39,11.71,2.7,13.8c1.33,2.1,3.31,3.42,2.76,8.17c-0.55,4.75,1.66,8.61,1,12.37
	c-0.67,3.76,2.42,7.06-3.97,14.8c0,0-0.52,7.72,4.15,13.14C542.13,512.49,544.45,514.25,545.44,511.82z M578.67,417.54
	c3.64,10.71,8.17,16.17,8.72,20.13c0.55,3.94-4.24,13.22-9.47,18.85c-5.21,5.63-19.13,21.19-21.88,25.83
	c-2.76,4.63-7.51,13.13-6.52,3.42c1-9.72,10.38-28.77,10.38-28.77s9.6-15.5,11.26-22.02c1.66-6.51,0.22-10.93-1.76-17.33
	c-1.99-6.41-6.73-25.28-6.73-25.28s-2.09-8.39,2.32-2.21c4.42,6.18,3.49,5.96,3.49,5.96S575.03,406.83,578.67,417.54z
	 M555.27,456.07c0,0-1.88-9.6,1.87-14.79c3.76-5.19,10.21-12.32,10.6-13.53c0.39-1.21,2.82,7.29-4.14,16.95
	C556.65,454.36,555.93,459.16,555.27,456.07z M636.74,446.61c1.99,3.31-3.53,3.64-6.73,6.04c-3.2,2.39-9.53-10.79-9.53-10.79
	s-4.05-4.3-4.2-4.34s-0.29-0.77-3.28-4.41c-2.98-3.64-18.1-11.71-22.74-15.9c-4.63-4.2-11.59-11.59-11.59-11.59
	s-11.71-18.1-12.25-28.48c-0.54-10.38,6.73,1.09,6.73,1.09c2.11,2.9,17.61,18.45,17.61,18.45s-0.11,1.33,4.25,3.75
	c4.36,2.43,9.6,7.51,9.6,7.51s5.52,5.45,13.94,5.48c8.23,0.04,8.32,2.56,8.32,2.68C628.05,431.38,634.75,443.3,636.74,446.61z
	 M485.13,356.9c0.74-9.56,0.44-11.52,4.01-12.66c3.57-1.14,2.61,4.49,2.26,8.5c-0.35,4.01-2.48,7.03-4.51,15.5
	c-2.03,8.46-4.85,5.74-7.17,3.34c-2.33-2.39-1.92-3.09,1.17-4.69C480.89,366.89,484.39,366.46,485.13,356.9z M470.98,366.04
	c0,0-2.43-7.12,2.54-11.15c4.96-4.03,8.83-4.91,8.05,0C480.81,359.8,475.73,369.85,470.98,366.04z M507.85,344.79
	c2.26,0.88,7.23-1.27,9.16-3.37c1.94-2.09,2.87-5.3,4.53-8.17c1.66-2.87,3.09-9.6,7.06-5.35c3.98,4.24,7.35,8.83,7.35,8.83
	s3.7,4.74,0.72,4.11c-2.97-0.63-4.16-5.05-5.85-3.72c-1.71,1.33-2.21,1.49,2.27,5.47c4.47,3.97,20.2,14.68,24.4,16.06
	c0,0,2.09,2.6-1.22,2.76c-3.3,0.16-11.86,1.22-20.81-3.2c-8.94-4.42-11.43-4.75-11.43-4.75s-0.45-3.54-2.7-3.03
	c-2.27,0.49-3.7,2.98-1.16,5.41c2.54,2.43,9.38,4.75,12.42,5.41c3.03,0.66,17.28,6.08,13.19,8.66c-4.09,2.6-13.41-0.93-16.01,0.67
	c-2.6,1.6-5.3-3.09-14.62-4.85c0,0-1.43-0.34-3.98-2.98c-2.54-2.65-4.47-7.51-4.47-7.51s-6.45-3.96-6.9-10.43
	C499.79,344.79,505.59,343.91,507.85,344.79z M520.75,379.17c0,0,12.29,4.23,12.95,5.81c0.67,1.58-1.4,2.09-6.03,3.34
	c-4.64,1.25-15.57,1.76-18.11-0.37c-2.54-2.13-3.86-3.67-3.86-3.67l-1.81-0.78l-0.44-1.88c0,0-2.21-0.78-3.17-3.01
	c-0.95-2.25-2.46-6.92-1.58-8.29c0.88-1.36,3.91,0.55,5.08,1.37C504.97,372.5,516.44,377.84,520.75,379.17z M657.24,370.9
	c10.3-1.68,12.72-2.64,12,1.89c-0.74,4.54-4.2,5.19-4.27,8.14c-0.08,2.95-10.08,2.79-23.33,2.72c-13.25-0.08-18.62-1.1-24.72-1.62
	c-6.11-0.51-16.05-1.18-19.28-3.6c-3.25-2.43-11.11-7-11.78-2.06c0,0,0.52,4.12,5.81,4.63c5.3,0.52,10.38,0.59,14.35,3.02
	c3.98,2.42,18.03,10.01,19.14,10.52c1.1,0.51,2.06,0,2.06,0l1.84,3.01c0,0,0.51-1.47,2.5,0c1.99,1.47,12.06,5.89,17,7.43
	c4.93,1.55,6.85,4.27,5.81,5.45c-1.03,1.18-3.16,6.63-13.62,5.16c-10.45-1.47-13.17-4.42-17.81-4.71
	c-4.63-0.29-11.41-0.15-18.1-6.18c-6.7-6.04-19.43-15.23-24.51-22.52c-5.08-7.29-12.37-12.51-12.3-17.01
	c0.08-4.49,7.81-12.58,10.82-12.43c3.01,0.15-1.33,3.24,1.7,3.46c3.01,0.22,3.75-2.43,6.11-0.88c2.35,1.54-0.51,3.97,2.8,4.49
	c3.3,0.51,7.36-0.22,10.22,1.84c2.88,2.06,14.65,9.5,22.82,10.23C630.67,372.61,646.93,372.57,657.24,370.9z M660.85,353.54
	c-0.59,2,1.47,5.3,3.46,8.84c1.99,3.53-0.96,4.34-4.56,3.9c-3.6-0.44-9.79,1.26-16.42,2.29c-6.62,1.03-18.55,0.29-24.22-0.96
	c-5.66-1.25-18.18-8.39-18.18-8.39c-1.69-1.91-4.63-1.03-7.21-4.26c-2.58-3.24,23.63-1.47,35.99-0.88
	c12.37,0.58,23.77-2.72,26.72-3.97C659.37,348.83,661.43,351.56,660.85,353.54z M653.93,323.52c5.37-1.04,6.33-2.21,8.91-2.8
	c2.58-0.59,2.58,1.03,2.5,4.12c-0.08,3.09,1.4,6.04,3.6,6.77c2.21,0.74,3.91,2.72,2.66,4.27c-1.26,1.54-4.86,8.02-7.14,3.67
	c-2.29-4.34-6.33-7.95-6.33-7.95S648.55,324.55,653.93,323.52z M639.64,328.89c0,0,8.69-4.63,13.25,1.47
	c4.56,6.11,6.34,9.05,7.66,11.18c1.33,2.14,1.1,3.98-4.41,4.86c-5.52,0.88-15.9,4.04-19.28,3.97c-3.39-0.08-20.98-0.96-28.12,0
	c-7.13,0.96-17.22-1.92-19.87,0.96c-2.66,2.87-9.35,2.2-6.7-1.1c2.65-3.31,12-8.32,14.72-8.1c2.72,0.22,5.15-0.54,9.2-1.3
	C610.14,340.08,639.64,328.89,639.64,328.89z M581.56,268.84c3.32-4.42,10.58-6.41,14.33-6.18c3.76,0.22,13.14-3.76,15.9-3.91
	c2.75-0.16,12.14,1.38,15.23,0.27c3.09-1.1,7.29,1.54,7.29,1.54s-0.22,3.64,7.39,15.09c7.62,11.44,12.47,6.55,12.37,11.85
	c-0.12,5.3-16.34,21.09-22.08,25.17c-5.74,4.09,0-4.53,4.31-7.95c4.3-3.42-2.21-16.12-3.87-20.53c-1.66-4.41-5.63-2.76-8.17-5.3
	c-2.54-2.54-0.45,2.66-7.72-0.77c-7.29-3.43-17.89-2.33-14.02-2.21c3.86,0.11,16.17,5.63,16.17,5.63s3.14-1.88,3.91,0
	c0.78,1.87,7.39,3.64,7.39,3.64s3.98,10.81,3.31,16.34c-0.66,5.51-8.28,9.83-11.14,15.23c-2.88,5.41-7.51,3.97-13.03,11.04
	c-5.51,7.06-24.94,15.23-24.94,15.23s-4.54,0.45-5.08-0.77c-0.55-1.22,4.96-2.66,7.06-3.54c2.09-0.88,5.29-5.18,5.51-8.5
	c0.22-3.31-10.26-19.65-13.35-24.73c-3.09-5.08,6.84-8.39,10.26-13.58c3.42-5.19,6.73-10.05,6.73-10.05s3.87-0.55,2.76-5.09
	c-1.1-4.53-4.97,0.01-8.28,7.07c-3.31,7.06-9.72,12.26-9.72,12.26s-2.54,4.63-4.97-0.45c-2.42-5.08-1.76-22.07-1.76-22.07
	S578.23,273.25,581.56,268.84z M570.21,338.61c0,0,0.73-2.21,3.08-5.19c2.35-2.98,3.06-10.38,4.72-16.01
	c1.65-5.63,2.97,0.67,5.63,5.52c2.65,4.85-1.1,7.29-1.1,7.29l-8.51,8.39C568.52,342.55,570.21,338.61,570.21,338.61z M564.21,265.96
	c1.65,1.44,0.88,8.61,0.95,15.46c0.07,6.85,6.44,22.41,10.19,25.83c3.76,3.42,1.1,5.85-0.55,6.96s-1.76,4.97-4.59,12.32
	c-2.83,7.36-4.46,6.89-5.56,5.68c-1.1-1.21-3.42-11.04-3.49-18.44c-0.07-7.39-4.57-11.59-7.44-7.62s4.53,3.87,3.87,12.69
	c-0.66,8.84,0.33,8.06,2.65,13.81c2.33,5.74-0.44,13.91-0.44,13.91c-1.3,10.05-4.64,6.84-13.92-2.54
	c-9.27-9.38-12.58-17.44-15.07-23.95c-2.49-6.51,9.33-13.69,11.1-19.55c1.77-5.84-4.93-19.53,0-20.86
	c4.94-1.33,12.92-5.96,16.22-10.71C561.43,264.2,562.56,264.53,564.21,265.96z M499.81,311.3c-0.88-3.83,0.08-6.77,1.91-8.46
	c1.84-1.7,10.6,9.42,12.89,13.76c2.28,4.34,5.59,1.54,5.29-0.3c-0.29-1.83-4.41-3.83-5.22-5.81c-0.8-1.99,1.4-4.34-0.88-5.45
	c-2.28-1.1-18.47-15.16-21.27-21.34c-2.8-6.18,0.51-7.36,0.51-7.36s3.68-4.63,6.18-8.91c2.5-4.26,5.3-6.62,7.95-8.83
	c2.66-2.21,5.16,0.96,5.16,0.96s9.5,13.47,11.56,16.34c2.05,2.87,4.19,0.59,2.79,0.22c-1.4-0.37-11.77-16.42-13.39-19.65
	c-1.62-3.24,3.6-5.96,4.56-7.06c0.95-1.1-0.3-3.02-3.39-11.19c-3.09-8.17,8.02-14.87,8.02-14.87c6.33-7.51,9.57-5.16,18.32-8.98
	c8.76-3.82,12.51-0.37,13.1,3.91c0.59,4.26,3.31,3.24,5.08,3.46c1.77,0.22,3.91,2.5,8.02,5.59c4.12,3.09,3.09,4.56,3.09,4.56
	l-1.1,19.21c0,0,0.3,2.21-1.99,0.37c-2.28-1.84-3.38,0-3.38,0s-8.1,18.85-17.3,21.72c-9.2,2.87-9.42,5.08-9.35,8.68
	c0.08,3.5-3.93,2.01-4.18,1.92c0.17,0.3,3.85,6.45,5.58,11.55c1.76,5.22-3.46,11.04-7.57,13.84c-4.12,2.79-3.26,9.27-3.26,9.27
	l-4.12,1.32l0.08,5.01c-2.95,1.1-6.41,8.46-6.26,10.96c0.15,2.51-3.01,3.91-5.16,4.86c-2.13,0.95-0.21-1.25-3.46-0.96
	c-3.24,0.29-11.41-1.62-12.37-2.35c-0.95-0.74-1.83-2.06,1.55-6.33C501.21,326.68,500.69,315.13,499.81,311.3z M470.87,340.84
	c-1.43-0.91-4.58,0.09-6.07-1.57c-1.49-1.66,6.78-5.14,6.78-5.14s17.77-11.37,22.36-11.43s3.97-0.66,3.37,1.33
	c-0.61,1.99-1.16,5.08-2.98,7.67c0,0-3.15,1.1-6.24,6.12s-8.38,6.62-8.38,6.62l-7.29,6.52c0,0-5.03,3.42-3.04-1.33
	C471.37,344.9,472.3,341.74,470.87,340.84z M422.09,366.36c-13.92-3.38-34.45-5.59-41.21-3.09c-6.77,2.5-8.27,1.85-13.18,5.37
	c-4.91,3.53-1.99-3.67-10.08-3.83c-8.1-0.15-31.14,0.08-45.42,1.63c-14.27,1.54-14.79-1.99-28.92-0.96
	c-14.14,1.03-10.68-0.45-23.11-0.22c-12.44,0.22-33.64-2.28-38.94-2.35c-5.3-0.08-23.77,2.06-26.72-2.87
	c-2.95-4.93-40.25,1.33-46.37,0.37c-6.11-0.96-44.23-1.62-44.23-1.62s-56.75-0.45-61.9,1.03c-5.15,1.47-0.37-4.71,3.31-5.59
	c3.68-0.88,67.05-1.47,72.2-1.4c5.16,0.07,41.36,0.15,45.7,1.4c4.34,1.25,7.8,0.8,13.18,0.29c5.37-0.51,10.23,1.62,17.96,1.1
	c7.72-0.51,61.97,5.38,79.78,3.83c17.81-1.54,17.3,0,23.26,0.08c5.96,0.07,8.68-1.33,12.66-1.18c3.97,0.15,13.4,2.8,23.85,1.4
	c10.45-1.4,16.19,0,16.42,0c0.21,0,4.93,1.04,8.24-1.69c3.31-2.72,11.71,1.1,15.97-1.1c4.27-2.21,3.44,1.54,7.65,0.8
	c4.21-0.74,15.18-3.01,19.07-2.65c3.91,0.37,15.9,3.13,26.2,0.13c0,0,2.87-2.04,7-1.89c4.12,0.15,1.54,2.16-4.27,7.59
	C424.37,366.36,436,369.74,422.09,366.36z M455.73,376c-4.93-1.55-10.97-3.68-12.22-5.52c-1.25-1.84-8.17-2.05-8.68-4.63
	c-0.52-2.58,2.87-5.3,6.55-7.73c3.67-2.42,5.88-7.29,5.88-7.29s7.21-8.17,13.47-7.36c6.26,0.81,2.35,4.42,1.03,5.96
	c-1.32,1.55-14.2,9.86-14.35,13.39c-0.15,3.54,0.02,3.54,2.07,3.76c2.05,0.22,3,0.37,3,0.37l4.56,5.88
	C457.05,372.83,460.65,377.54,455.73,376z M459.5,370.24c-2.04-5.19-3.8-5.31-1.99-7.84c0,0,7.84-11.87,7.89-5.08
	c0.06,6.78-0.05,9.55,2.76,14.29c2.82,4.75,4.81,8.28,0.94,8.28C465.23,379.89,461.54,375.43,459.5,370.24z M481.17,377.98
	c0.39,0.96-0.13,2.95-2.36,2.87c-2.25-0.07-3.02-2.63-3.02-2.63C476.55,373.65,480.79,377.02,481.17,377.98z M469.6,386.63
	c0,0-3.91,1.55-10.32-0.6c-6.41-2.15-9.8-7.57-9.8-7.57C452.15,376.59,469.6,386.63,469.6,386.63z M494.05,402.92
	c1.6-5.75,1.15-17.5-4.97-21.53c-6.12-4.03,0.61-8.44,0.61-8.61c0-0.16,1.82-2.21,1.27-6.46c-0.55-4.24,2.59-9.99,2.59-9.99
	s0.16-5.85,0.61-9.38c0.45-3.54,4.75,3.03,4.75,3.03s1.49,9.38,6.9,13.96c0,0,3.64,3.31,1.27,5.24c-2.37,1.94-2.21,0.39-4.58-1.27
	c-2.37-1.66-7.5-4.96-8.05,2.6c-0.55,7.56,6.29,13.63,6.29,13.63h1.66l0.72,1.93c0,0,7.01,5.52,9.5,6.02
	c2.48,0.5,6.01-0.27,6.4,1.16c0.39,1.43-21.64,11.87-21.64,11.87S492.45,408.65,494.05,402.92z M495.02,410.81
	c3.02-4.63,17.38-8.61,17.38-8.61s6.47-4.05,12.21-5.38c5.75-1.32,4.5-3.67,4.35-4.85c-0.14-1.17,3.46-3.17,7.95-3.01
	c4.49,0.15,4.12-1.78,4.12-1.78s-1.4-1.46-2.42-2.27c-1.04-0.81-0.67-2.35-2.36-4.05c-1.69-1.69-10.67-2.65-16.48-6.04
	c-5.81-3.38,9.21,0.45,9.21,0.45c7.81,2.06,10.16,3.11,10.16,3.11c2.5-0.49,9.93,1.38,12.06,4.69c2.13,3.31-2.42,5.89-4.63,8.39
	c-2.21,2.5-7,2.58-10.89,2.42c-3.91-0.15-9.13,5.81-10.52,7c-1.41,1.18-0.59,3.31-0.59,3.31s-2.43,1.47-5.53,8.17
	c-3.1,6.7-13.9,9.86-19.93,11.63C493.04,425.75,492.01,415.45,495.02,410.81z M517.48,440.39c-4.05,4.72-11.77,17.23-12.96,20.1
	c-1.17,2.87-5.76,4.63-6.09-1.4c-0.32-6.04-0.71-7.96-0.71-7.96s-1.6-3.09,0.32-8.09c1.91-5.01,0.39-7.73,0.39-7.73
	s-2.74-1.25,5.28-4.19c8.01-2.95,19.43-13.47,22.3-17.6c2.87-4.12,3.6,2.21,2.05,6.63C526.53,424.58,521.52,435.68,517.48,440.39z
	 M538.6,500.01c-2.71-4.8-1.66-4.96-0.5-7.83c1.16-2.88,3.59-6.24,1.72-17.67c-1.14-6.93-1.37-8.39-1.37-8.48
	c0.02,0.05,0.1,0.47,0.1,0.47s0.94-4.85-1.88-9.5c-2.81-4.63,0.45-8.23-3.2-13.47c-3.64-5.24-0.72-14.74-0.72-14.74
	s0.39-6.4,0.39-9.71c0-3.31,2.48-2.87,1.54-4.48c-0.94-1.6-4.47-0.72-4.08-6.35c0.38-5.63,2.09-9.87,5.74-9.83
	c3.64,0.06,10.6-1.23,16.28-9.03c0,0,3.47-6.8,5.51-2.39c2.05,4.42,5.58,26.22,7.52,29.7c1.93,3.48,0.05,8.56-3.09,11.48
	c-3.15,2.93-9.99,5.84-10.82,19.31c-0.82,13.47-2.81,21.64-5.74,33.57C543.07,493,541.31,504.81,538.6,500.01z"
      />
    </svg>
  );
}

export default Flower;
